
import { appLinkList } from "@/static/appLinks";
import FIcon from '@fixit/icons/src/components/FIcon.vue'
import ChooseSalonDialog from '../../components/chooseSalonDialog.vue';
export default {
  components: { FIcon, ChooseSalonDialog },
  head() {
    return {
      title: "Appen",
      titleTemplate: `%s |  ${this.$store.getters["cmsConfigHomepage/getChainName"]}`,
    };
  },
  layout: "HomepageLayout",
  name: "app",

  data: () => ({
    isLandingPage: false,
    isMounted: false,
    bannerImgRegular:
      "//res.cloudinary.com/odin-systemer/image/upload/w_1170,h_300,g_face,g_face,c_fill,g_center,e_sharpen:100/v1504091424/resources/Hjemmeside_Malbilder/salong_app.jpg",
    bannerImgMobile:
      "//res.cloudinary.com/odin-systemer/image/upload/w_500,h_250,g_face,g_face,c_fill,g_center,e_sharpen:100/v1504091424/resources/Hjemmeside_Malbilder/salong_app.jpg",
  }),
  computed: {
    linkList() {
      return appLinkList();
    },
    isDraft() {
      return this.$store.getters['cmsConfigHomepage/isDraftMode']
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    normalRedirect(link) {
      window.open(link)
    },
  }
};
